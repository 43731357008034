<template>
  <v-container>
    <v-row>
      <v-col>
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="formData.auth.displayName"
            label="Display name"
            :rules="[required]"
          ></v-text-field>
          <v-text-field
            v-model="formData.auth.email"
            label="Email"
            :rules="[required]"
          ></v-text-field>
          <v-text-field
            v-model="formData.auth.phone"
            label="Phone"
            :rules="[required]"
          ></v-text-field>
          <br>
          <v-btn
            color="primary"
            @click="createUser"
            :loading="saving"
          > CREATE
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getCurrentUser, createUser } from '@/services/firebase';

export default {
  name: 'UserAdd',
  data() {
    return {
      formData: {
        auth: {
          disabled: false,
          displayName: '',
          email: '',
          phone: '',
        },
      },
      saving: false,
      required: (value) => !!value || 'Field required',
    };
  },
  methods: {
    formValidation() {
      const isDisplayName = Boolean(this.formData.auth.displayName !== '');
      const isPhone = Boolean(this.formData.auth.phone !== '');
      const isEmail = Boolean(this.formData.auth.email !== '');

      const isValid = isDisplayName && isPhone && isEmail;

      return isValid;
    },

    createUser() {
      const validation = this.formValidation();

      if (!validation) {
        this.$refs.form.validate();
      } else {
        const payload = {
          auth: {
            phone: this.formData.auth.phone,
            email: this.formData.auth.email,
            displayName: this.formData.auth.displayName,
            disabled: false,
          },
          adviser: getCurrentUser().uid,
          role: 'client',
        };

        this.saving = true;

        createUser(payload)
          .then(() => {
            this.$router.push({ name: 'UsersIndex' });
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
};
</script>

<style scoped>

</style>
