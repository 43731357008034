var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Display name",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.formData.auth.displayName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "displayName", $$v);
      },
      expression: "formData.auth.displayName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Email",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.formData.auth.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "email", $$v);
      },
      expression: "formData.auth.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Phone",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.formData.auth.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "phone", $$v);
      },
      expression: "formData.auth.phone"
    }
  }), _c('br'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.saving
    },
    on: {
      "click": _vm.createUser
    }
  }, [_vm._v(" CREATE ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }